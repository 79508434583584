import React, { useContext, useEffect } from 'react'
import { inject, observer } from 'mobx-react'
import { format } from 'date-fns'
import styled, { css } from 'styled-components/macro'
import { useMediaQuery } from 'react-responsive'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'

import { GOOGLE_API_KEY, ORDER_TYPES } from 'utils/constants'
import { formatPrice, codeToLocale, getTranslatedTextByKey, getLocaleStr } from 'utils/utils'
import { limitTextNumberOfLines, breakpoints, respondAbove, respondTo } from 'styles/mixins'
import TypographyPro from 'themes/TypographyPro'
import ButtonBase from 'components/common/ButtonBase'
import { StoreContext } from '../../../contexts/StoreContext'
import type { InfraProps } from 'mobx/Infra/Infra.type'
import type { OrderEntity } from 'types/OrderEntity'
import storage from '../../../utils/storage'
import { useRouter } from 'next/router'

const OrderFirstItemName = styled(TypographyPro)`
	text-transform: uppercase;
	color: var(--strokeMenuTitle);
	${limitTextNumberOfLines(1)}
`

const MoreItems = styled(TypographyPro)`
	color: var(--fonts);
`

const PriceText = styled(TypographyPro)`
	color: var(--clear);
`

const TimeText = styled(TypographyPro)`
	text-transform: capitalize;
	color: var(--inactive);
	text-align: end;
`

const DeliveryMethodText = styled(TimeText)`
	text-align: start;
	color: var(--primary);
	text-transform: uppercase;
`

const Row = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-direction: row;
	width: 100%;
	overflow: hidden;
	margin-bottom: ${(props: { height?: string; $marginBottom?: string }) => (props.$marginBottom ? props.$marginBottom : '')};
	height: ${(props: { height?: string; $marginBottom?: string }) => (props.height ? props.height : 'auto')};
`

const Container = styled.div<{ $isHomePage?: boolean; $isMobileView?: boolean }>`
	height: ${(props: { $isMobileView?: boolean; $isHomePage?: boolean }) => (props.$isMobileView ? '179px' : '192px')};

	${({ $isHomePage }) =>
		$isHomePage
			? css`
					min-width: 400px;
					${(respondTo as any).sm`
					min-width: 320px;
					child
				`}
			  `
			: css`
					width: 90%;
					${(respondAbove as any).xl`
						min-width: 398px;
					`}
			  `}

	display: flex;
	flex-direction: column;
	background: #ffffff;
	justify-content: space-between;
	padding: 20px;
	border-radius: var(--borderRadiusFrame);
	box-shadow: var(--defaultShadow1);

	${(respondAbove as any).xl`
		width: 393px;
	`}
`

const ReorderButton = styled<any>(ButtonBase)`
	display: flex;
	justify-content: space-between;
	text-transform: uppercase;
	width: 100%;
`

const getOrderType = (orderType: string) => {
	// TODO: Should use type here !
	if (!orderType) {
		return '---'
	}
	switch (orderType.toLowerCase()) {
		case ORDER_TYPES.PEAKUP:
			return getTranslatedTextByKey(ORDER_TYPES.PEAKUP, 'Pickup')
		case ORDER_TYPES.DELIVERY:
			return getTranslatedTextByKey(ORDER_TYPES.DELIVERY, 'Delivery')
		default:
			return orderType
	}
}

interface Store {
	Infra?: InfraProps
	User?: {
		preferredLanguage: 'en' | 'iw' | 'ar' | 'ru' | 'pt' | 'es' | 'fr' | 'pap' | 'de' | 'fi'
		[key: string]: any
	}
	Application?: any
	AddressManager?: any
	Cart?: any
	MobileApplication?: any
	ItemAdditions?: any
}

type OrderProps = {
	orderData: OrderEntity
	width?: string
	isHomePage?: boolean
} & Store

const Order = inject(
	'Infra',
	'User',
	'Application',
	'AddressManager',
	'Cart',
	'MobileApplication',
	'ItemAdditions'
)(
	observer(({ orderData, isHomePage, Infra, AddressManager, User, Application, Cart, MobileApplication, ItemAdditions }: OrderProps) => {
		const isMobileView = useMediaQuery({ query: `(max-width: ${breakpoints.sm})` })
		const _currency = Infra?.appParams?.currency || 'EUR'
		const preferredLanguage = User?.preferredLanguage || 'en'
		const langFromInfra = Infra?.appParams?.l || 'en'
		const locale = User?.preferredLanguage ? codeToLocale[preferredLanguage] : codeToLocale[langFromInfra]
		const _key = Infra?.locale?.key?.trim() || GOOGLE_API_KEY
		const { placesService, placePredictions, getPlacePredictions } = usePlacesService({
			apiKey: _key,
		})

		const { store, setStore } = useContext(StoreContext)
		const router = useRouter()

		useEffect(() => {
			// fetch place details for the first element in placePredictions array
			if (placePredictions.length) {
				placesService?.getDetails(
					{
						placeId: placePredictions[0].place_id,
					},
					(placeDetails: any) => {
						AddressManager.setFullAddress(placeDetails, orderData.orderType)
						// attempt the re-order
						Cart.reOrder(
							orderData,
							locale,
							setStore,
							router,
							ItemAdditions.reset,
							Infra,
							User,
							Application.isMobileApp,
							MobileApplication,
							ItemAdditions,
							storage,
							store
						)
					}
				)
			}
			Infra?.setLoading(false)
		}, [placePredictions])

		const openPastOrderSummary = () => {
			const DAY_IN_MS = 24 * 60 * 60 * 1000
			const isOrderRecent = orderData.lastUsed && new Date(orderData.lastUsed).getTime() > new Date().getTime() - DAY_IN_MS
			const isLinkDefined = !!orderData?.props?.orderConfirmationURL
			if (isOrderRecent && isLinkDefined) {
				if (Application.isMobileApp) {
					window.location.href = orderData?.props?.orderConfirmationURL
				} else {
					// @ts-ignore
					window?.open(orderData?.props?.orderConfirmationURL, '_blank').focus()
				}
			}
		}

		const reOrder = (e: Event) => {
			// prevent opening this order's summary page in a new tab
			e.stopPropagation()
			Infra?.setLoading(true)
			getPlacePredictions({ input: orderData.address.formatted })
		}

		const moreItemsText = (order: any) => {
			const totalOrderItems = order?.courseList?.length
			const moreItemsText = `+${totalOrderItems - 1} ${getTranslatedTextByKey('eCommerce.accountSettings.moreItemsInTheOrder', 'more item(s)')}`
			return totalOrderItems > 1 ? <MoreItems variant="Subhead">{moreItemsText}</MoreItems> : ''
		}

		const orderFirstItemName = orderData?.courseList?.length && getLocaleStr(orderData?.courseList?.[0].title, locale)

		return (
			<Container className="pastOrderContainer" $isHomePage={isHomePage} $isMobileView={isMobileView} onClick={() => openPastOrderSummary()}>
				<Row>
					<OrderFirstItemName variant="h4">{orderFirstItemName}</OrderFirstItemName>
				</Row>

				<Row $marginBottom="10px" height="24px">
					{moreItemsText(orderData)}
				</Row>

				<Row $marginBottom="6px">
					<TimeText variant="BodySmall">{orderData?.lastUsed ? format(new Date(orderData?.lastUsed), 'MMM dd, yyyy') : ''}</TimeText>
					<TimeText variant="BodySmall">{orderData?.lastUsed ? format(new Date(orderData?.lastUsed), 'HH:mm a') : ''}</TimeText>
				</Row>

				<Row $marginBottom="10px">
					<DeliveryMethodText variant="h5">
						{orderData?.orderType?.toLowerCase() === ORDER_TYPES.PEAKUP
							? getTranslatedTextByKey('eCommerce.orderType.pickup', 'Pickup')
							: getOrderType(orderData?.orderType)}
					</DeliveryMethodText>
				</Row>

				<Row>
					<ReorderButton className="pastOrderButton" onClick={reOrder}>
						{getTranslatedTextByKey('eCommerce.accountSettings.viewOrderButton', 'View Order')}
						<PriceText variant="CTASmall">{formatPrice(orderData?.grandTotalIncludingAll, _currency, locale)}</PriceText>
					</ReorderButton>
				</Row>
			</Container>
		)
	})
)

export default Order
